<template>
  <section
    id="detention"
    class="overflow-hidden"
  >
    <v-row no-gutters>
      <v-col
        class="primary text-center pa-5 white--text"
        cols="12"
        md="6"
      >
        <base-heading>
          More Than Blue<br>
          悲しみより、もっと悲しい物語
        </base-heading>

        <base-text class="mb-5 text-left">
          <p>ドラマ「悲しみより、もっと悲しい物語」は、レコード会社の重役柏翰とアシスタントの安安が、歌姫のヒット曲「もっとも悲しいこと」の原曲の作曲家と作詞家を探すのを手伝おうとする物語。音楽業界のベテラン吉の兄貴とキャットちゃんの不思議な力を借りて、柏翰と安安は、K張哲凱とクリーム宋媛媛の悲しいラブストーリーを解き明かし、心優しい歯医者の楊祐賢と写真家のシンディが愛し合いながら殺し合う物語を知ることになる。</p>
        </base-text>

        <v-card color="secondary">
          <v-container class="pa-2">
            <v-row>
              <v-col cols="12">
                <v-img :src="require(`@/assets/More Than Blue_1.jpg`)" />
              </v-col>
              <v-col cols="12">
                <v-img :src="require(`@/assets/More Than Blue_2.jpg`)" />
              </v-col>
              <v-col cols="6">
                <v-img :src="require(`@/assets/More Than Blue_3.jpg`)" />
              </v-col>
              <v-col cols="6">
                <v-img :src="require(`@/assets/More Than Blue_5.jpg`)" />
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>

      <v-col
        class="hidden-sm-and-down"
        md="6"
      >
        <v-img
          :src="require('@/assets/More Than Blue_4.jpg')"
          height="100%"
        />
      </v-col>
    </v-row>
  </section>
</template>

<script>
  export default {
    name: 'MoreThanBlue',
  }
</script>
